import Http from '../../utils/serviceUtils'
const { TARO_APP_BASE } = process.env

const apis = {
  getBrief: (params) => Http.post(`${TARO_APP_BASE}/mpbp/bp/news/v4/aigc/autoBrief`, params),
  // getBrief: (params) => Http.post(`${TARO_APP_BASE}/v3/news/auto/brief`, params),
  NewsPubDirect: (id) => Http.post(`${TARO_APP_BASE}/mpbp/bp/news/v4/news/${id}/publishDirect`),
  NewsPubLimitGet: (params) => Http.get(`${TARO_APP_BASE}/mpbp/bp/news/v4/news/publishLimit/`, params),
  NewsPub: (params) => Http.post(`${TARO_APP_BASE}/mpbp/bp/news/v4/news/publish`, params),
  NewsDraft: (params) => Http.post(`${TARO_APP_BASE}/mpbp/bp/news/v4/news/draft`, params),
  FileUpload: (params) => Http.post(`${TARO_APP_BASE}/commons/front/outerUpload/image/file`, params, {
    'Content-Type': 'multipart/form-data'
  }),
  queryTopicInfo: (params) => Http.post(`${TARO_APP_BASE}/mpbp/bp/xtopic/simple/listByIds`, params, { 'Content-Type': 'application/json' }),
  ChannelsGet: (params) => Http.get(`${TARO_APP_BASE}/mpbp/bp/account/common/channels-data-api`, params),
  CategoriesGet: (channelId) => Http.get(`${TARO_APP_BASE}/mpbp/bp/account/common/channels/${channelId}/categories`),
  NewsAttrsGet: () => Http.get(`${TARO_APP_BASE}/mpbp/bp/news/v4/news/attribute`),
  NewsColumnList: () => Http.get(`${TARO_APP_BASE}/mpbp/bp/account/column/v2/list`, { page: 1,size: 100 }),
  RightInterestGet: (params) => Http.get(`${TARO_APP_BASE}/sharing/v2/account/rightInterest`, params),
  queryRecommendTopic: (params) => Http.get(`${TARO_APP_BASE}/mpbp/bp/xtopic/noauth/recommend`, params),
  queryVaildTopic: (params) => Http.post(`${TARO_APP_BASE}/mpbp/bp/xtopic/relation/valid`, params, { 'Content-Type': 'application/json' }),
  searchTopics: (params) => Http.post(`${TARO_APP_BASE}/mpbp/bp/xtopic/noauth/query`, params, { 'Content-Type': 'application/json' }),
  // 获取最近草稿信息
  getLastDraftInfo: (params) => Http.get(`${TARO_APP_BASE}/mpbp/bp/news/v4/getLastDraftInfo`, params ),
}

export default apis
