import Http from '../../utils/serviceUtils'
const { TARO_APP_BASE } = process.env
const baseUrl = TARO_APP_BASE + '/mpbp/bp/account'

const apis = {
  // 获取模版问题list
  getQuestionList: (params) => Http.get(`${baseUrl}/feedback/getTemplateList`, params),

  // 获取H5模版list
  getH5TemplateList: (params) => Http.get(`${baseUrl}/feedback/getH5TemplateList`, params),

  // 根据模版id获取具体信息
  getTemplateInfo: (params) => Http.get(`${baseUrl}/feedback/getTemplateInfo`, params),

  // 反馈历史列表
  getHistoryList: (params) => Http.get(`${baseUrl}/feedback/getHistoryList`, params),

  // 查看反馈详情
  getFeedBackTplDetail: (params) => Http.get(`${baseUrl}/feedback/query`, params),

  // 提交反馈
  submitFeedback: (params) => Http.post(`${baseUrl}/feedback/insertOrUpdate`, params, { 'Content-Type': 'application/json' }),

  // 获取账号注销验证资产
  getAssetInfo: (params) => Http.get(`${baseUrl}/feedback/getAssetInfo`, params),

  // 身份证是否被占用
  getOccupyDetail: (params) => Http.get(`${baseUrl}/find/occupy-detail`, params),

  // 反馈获取被占用账号信息
  getFeedbackAccountInfo: (params) => Http.get(`${baseUrl}/feedback/get-accounts`, params),
}

export default apis
