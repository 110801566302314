import Taro from '@tarojs/taro';
import Storage from '@/utils/storageUtils';
import { customRoutes } from './config';
const { TARO_ENV } = process.env;

class Router {
  async push(url, params, dynamicId) {
    const result = await this.beforeEach(url);
    if (!result) return;

    const paramsStr = this.handleParams(params);
    if ((dynamicId || dynamicId === 0) && TARO_ENV === 'h5') {
      this.toH5DynamicUrl(url, dynamicId, paramsStr);
      return;
    }

    Storage.set('toPath', url);
    if (process.env.TARO_ENV !== 'h5' && Taro.getCurrentPages().length >= 10) {
      Taro.redirectTo({
        url: url + paramsStr,
      });
    } else {
      Taro.navigateTo({
        url: url + paramsStr,
      });
    }
  }
  async replace(url, params, dynamicId) {
    const result = await this.beforeEach(url);
    if (!result) return;

    const paramsStr = this.handleParams(params);

    if ((dynamicId || dynamicId === 0) && TARO_ENV === 'h5') {
      this.toH5DynamicUrl(url, dynamicId, paramsStr);
      return;
    }

    Storage.set('toPath', url);
    Taro.redirectTo({
      url: url + paramsStr,
    });
  }
  async beforeEach(url) {
    // 是否通过路由守卫
    let result = true;
    // 当前路由信息
    const from = Taro.getCurrentInstance().router;
    // 通用校验
    const common = await this.checkCommon(url, from);
    // 仅小程序的校验
    if (TARO_ENV === 'weapp') {
      const wx = await this.checkWx(url, from);
      result = common && wx;
    }
    // 仅h5的校验
    if (TARO_ENV === 'h5') {
      const h5 = await this.checkH5(url, from);
      result = common && h5;
    }
    return result;
  }
  checkCommon() {
    const result = true;
    return result;
  }
  checkWx(url, from) {
    let result = true;
    Storage.set('fromPath', from.path);
    if (url === from.path) {
      result = false;
    }
    return result;
  }
  checkH5(url, from) {
    let result = true;
    const toPath = customRoutes[url];
    const fromPath = from.path.split('?')[0];
    for (const item in customRoutes) {
      if (customRoutes[item].includes(fromPath)) {
        Storage.set('fromPath', item);
      }
    }
    if (toPath.includes(fromPath)) {
      result = false;
    }
    return result;
  }
  toH5DynamicUrl(url, dynamicId, params) {
    const toPath = customRoutes[url];
    const result = toPath.replace(':id', dynamicId);
    Storage.set('toPath', result);
    window.open(result + params, '_self');
  }
  handleParams(params) {
    let paramsStr = '';
    if (params && typeof params === 'object') {
      const keys = Object.keys(params) || [];
      if (keys && keys.length) {
        keys.forEach((key, index) => {
          if (index === 0) {
            paramsStr += `?${key}=${params[key]}`;
          } else {
            paramsStr += `&${key}=${params[key]}`;
          }
        });
      }
    }
    return paramsStr;
  }
}

export default new Router();
