import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import app from './modules/app'
import privacy from './modules/private'
import recoverAccount from './modules/recoverAccount'
import edit from './modules/edit'
import content from './modules/content'
const { TARO_ENV } = process.env

const obj = {
  modules: {
    app,
    privacy,
    edit,
    content,
    recoverAccount
  },
  plugins: []
}

if (TARO_ENV === 'h5') {
  obj.plugins.push(createPersistedState({
    storage: window.localStorage,
    paths: ['app', 'edit', 'content']
  }))
}

const store = createStore(obj);

export default store;
