import Http from '../../utils/serviceUtils'
const { TARO_APP_CODE } = process.env
const baseUrl = TARO_APP_CODE + '/sapi'

const apis = {
  JvCode: (params, headers) => Http.post(`${baseUrl}/jf/code`, params, headers),
  GetGid: (params, headers) => Http.post(`${baseUrl}/g`, params, headers),
  GetCaptcha: (params, headers) => Http.post(`${baseUrl}/captcha`, params, headers),
  SendCheckCode: (params, headers) => Http.post(`${baseUrl}/captcha/check`, params, headers),
  GetMobileCode: (params, headers) => Http.post(`${baseUrl}/mobile/sendcode`, params, headers)
}

export default apis
