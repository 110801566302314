const app = {
  namespaced: true,
  state: {
    userInfo: {}, // 账号信息
    sysInfo: {}, // 设备信息
    eventInfo: {}, // 活动信息(内嵌)
    openId: '',
    gid: '',
    jv: '',
    cookie: '', // 小程序cookie
    cookieTime: '', // cookie过期时间
    totalAccount: 0,
    innerAcc: false, // 是否内部账号
  },
  mutations: {
    UPDATE_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    UPDATE_SYSINFO: (state, sysInfo) => {
      state.sysInfo = sysInfo
    },
    UPDATE_EVENTINFO: (state, eventInfo) => {
      state.eventInfo = eventInfo
    },
    UPDATE_OPENID: (state, openId) => {
      state.openId = openId
    },
    UPDATE_GID: (state, gid) => {
      state.gid = gid
    },
    UPDATE_JV: (state, jv) => {
      state.jv = jv
    },
    UPDATE_COOKIE: (state, cookie) => {
      state.cookie = cookie
    },
    UPDATE_COOKIETIME: (state, cookieTime) => {
      state.cookieTime = cookieTime
    },
    UPDATE_TOTALACCOUNT: (state, totalAccount) => {
      state.totalAccount = totalAccount
    },
    UPDATE_INNERACC: (state, innerAcc) => {
      state.innerAcc = innerAcc
    },
  },
  actions: {
    UpdateUserInfo: (ctx, userInfo) => {
      ctx.commit('UPDATE_USERINFO', userInfo)
    },
    UpdateSysInfo: (ctx, sysInfo) => {
      ctx.commit('UPDATE_SYSINFO', sysInfo)
    },
    UpdateEventInfo: (ctx, eventInfo) => {
      ctx.commit('UPDATE_EVENTINFO', eventInfo)
    },
    UpdateOpenId: (ctx, openId) => {
      ctx.commit('UPDATE_OPENID', openId)
    },
    UpdateGid: (ctx, gid) => {
      ctx.commit('UPDATE_GID', gid)
    },
    UpdateJv: (ctx, jv) => {
      ctx.commit('UPDATE_JV', jv)
    },
    UpdateCookie: (ctx, cookie) => {
      ctx.commit('UPDATE_COOKIE', cookie)
    },
    UpdateCookieTime: (ctx, cookieTime) => {
      ctx.commit('UPDATE_COOKIETIME', cookieTime)
    },
    UpdateTotalAccount: (ctx, totalAccount) => {
      ctx.commit('UPDATE_TOTALACCOUNT', totalAccount)
    },
    UpdateInnerAcc: (ctx, innerAcc) => {
      ctx.commit('UPDATE_INNERACC', innerAcc)
    }
  }
}

export default app
