const edit = {
  namespaced: true,
  state: {
    columnList: [],
    attrs: [],
    categories: [],
    channels: [],
    editingPic: '',
    cover: '',
    editingText: '',
    editingInfo: {},
    originalAuth: false,
    selectedImages: {
      index: -1,
      images: [],
    },
    topicIds: []
  },
  mutations: {
    UPDATE_COLUMNLIST: (state, payload) => {
      state.columnList = payload;
    },
    UPDATE_ATTRS: (state, payload) => {
      state.attrs = payload;
    },
    UPDATE_CATEGORIES: (state, payload) => {
      state.categories = payload;
    },
    UPDATE_CHANNELS: (state, payload) => {
      state.channels = payload;
    },
    UPDATE_EDITINGPIC: (state, payload) => {
      state.editingPic = payload;
    },
    UPDATE_COVER: (state, payload) => {
      state.cover = payload;
    },
    UPDATE_EDITINGTEXT: (state, payload) => {
      state.editingText = payload;
    },
    UPDATE_EDITINGINFO: (state, payload) => {
      state.editingInfo = payload;
    },
    UPDATE_ORIGINALAUTH: (state, payload) => {
      state.originalAuth = payload;
    },
    UPDATE_SELECTEDIMAGES: (state, payload) => {
      state.selectedImages = payload;
    },
    UPDATE_TOPICIDS: (state, payload) => {
      if (Array.isArray(payload)) {
        state.topicIds = payload;
      }
    },
  },
  actions: {
    UpdateColumnList: (ctx, columnList) => {
      ctx.commit('UPDATE_COLUMNLIST', columnList)
    },
    UpdateAttrs: (ctx, attrs) => {
      ctx.commit('UPDATE_ATTRS', attrs)
    },
    UpdateCategories: (ctx, categories) => {
      ctx.commit('UPDATE_CATEGORIES', categories)
    },
    UpdateChannels: (ctx, channels) => {
      ctx.commit('UPDATE_CHANNELS', channels)
    },
    UpdateEditingPic: (ctx, editingPic) => {
      ctx.commit('UPDATE_EDITINGPIC', editingPic)
    },
    UpdateCover: (ctx, cover) => {
      ctx.commit('UPDATE_COVER', cover)
    },
    UpdateEditingText: (ctx, editingText) => {
      ctx.commit('UPDATE_EDITINGTEXT', editingText)
    },
    UpdateEditingInfo: (ctx, editingInfo) => {
      ctx.commit('UPDATE_EDITINGINFO', editingInfo)
    },
    UpdateOriginalAuth: (ctx, originalAuth) => {
      ctx.commit('UPDATE_ORIGINALAUTH', originalAuth)
    },
    UpdateSelectedImages: (ctx, selectedImages) => {
      ctx.commit('UPDATE_SELECTEDIMAGES', selectedImages)
    },
    UpdateTopicIds: (ctx, topicIds) => {
      ctx.commit('UPDATE_TOPICIDS', topicIds)
    }
  }
}

export default edit
