const recoverAccount = {
  namespaced: true,
  state: {
    recoverToken: '', // 账号找回鉴权token
  },

  mutations: {
    SET_RECOVERTOKEN: (state, token) => {
      state.recoverToken = token
    }
  },

  actions: {
    UpdateRecoverToken(ctx, info) {
      ctx.commit('SET_RECOVERTOKEN', info)
    }
  }
}

export default recoverAccount
