import Http from '../../utils/serviceUtils'
const { TARO_APP_BASE } = process.env
const baseUrl = TARO_APP_BASE

const apis = {
  // 获取作者入驻信息
  checkRegisterInfo: (params) => Http.get(`${baseUrl}/mpbp/bp/account/register-info`, params),
  // 账号名称校验
  checkNickName: (params) => Http.get(`${baseUrl}/mpbp/bp/account/common/nickName/status`, params),
  // 上传头像
  uploadCover: (params) => Http.post(`${baseUrl}/mpbp/bp/file/uploadFile`, params),
  // 获取频道信息
  getChannel: (params) => Http.get(`${baseUrl}/mpbp/bp/account/common/channels`, params),
  // 身份证校验
  checkCertNo: (params) => Http.get(`${baseUrl}/mpbp/bp/account/h5/cert-check`, params),
  // 身份证ocr
  gethOcr: (params) => Http.get(`${baseUrl}/mpbp/bp/account/h5/ocr`, params),
  // 实名认证
  getFaceCheck: (params) => Http.get(`${baseUrl}/mpbp/bp/account/h5/face-check`, params),
  // 获取风控等级
  getRiskLevel: (params) => Http.get(`${baseUrl}/mpbp/bp/account/h5/get-risk-level`),
  // 获取城市信息
  getCommonCityInfo: (params) => Http.get(`${baseUrl}/mpbp/bp/account/common/cityInfo`, params),
  // 身份证占用找回
  accountFindApply: (params) => Http.post(`${baseUrl}/mpbp/bp/account/find/apply`, params, { 'Content-Type': 'application/json' }),
  // 身份证占用找回 人脸认证
  accountFindFace: (params) => Http.get(`${baseUrl}/mpbp/bp/account/find/face-check`, params),
  // 确认账号找回（找回次数校验）
  getSecConfirm: () => Http.get(`${baseUrl}/mpbp/bp/account/find/sec-confirm`),
  // 风控校验
  getRiskCheck: () => Http.get(`${baseUrl}/mpbp/bp/account/find/risk-check`),
  // 身份证占用详情
  getOccupyDetail: (params) => Http.get(`${baseUrl}/mpbp/bp/account/find/occupy-detail`, params),
  //个人号入驻
  getRegister: (params) => Http.post(`${baseUrl}/mpbp/bp/account/h5/register`, params, { 'Content-Type': 'application/json' }),
  //账号找回获取账号信息
  getFindAccountInfo: () => Http.get(`${baseUrl}/mpbp/bp/account/find/get-accounts`),
  // E证通获取人脸识别的token
  getFaceToken: (params) => Http.get(`${baseUrl}/mpbp/bp/account/face-token`, params),
  // E证通人脸识别结果
  getFaceResult: (params) => Http.get(`${baseUrl}/mpbp/bp/account/face-result-token`, params),
  //安全分获取人脸验证结果
  getAccountFaceResult: (params) =>Http.get(`${baseUrl}/mpbp/bp/account/security-score/get-face-result`, params),
  //安全分获取人脸验证
  getAccountFaceUrl: (params) =>Http.get(`${baseUrl}/mpbp/bp/account/security-score/get-face-url`, params),

}

export default apis
