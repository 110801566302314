import Http from '../../utils/serviceUtils'
const { TARO_APP_BASE } = process.env
const baseUrl = TARO_APP_BASE + '/mpbp/bp/account'

const apis = {
  // 获取账号列表
  getAccounts: () => Http.get(`${baseUrl}/listV2`),
  // 人脸识别token
  getFaceToken: (params) => Http.get(`${baseUrl}/face-token`, params),
  // 入驻信息
  // getRegisterInfo: (params) => Http.get(`${baseUrl}/register-info`, params),
  // 人脸识别结果
  getFaceResult: (params) => Http.get(`${baseUrl}/face-result-token`, params),
  // 检验user和accountid状态 & 用户唯一标识
  accountAndUserCheck: () => Http.get(`${baseUrl}/check/user`),
  // 添加新手期账号
  preRegister: () => Http.post(`${baseUrl}/pre-register`),
  // 获取审核理由
  getAccountReason: (params) => Http.get(`${baseUrl}/audit-reason`, params),
  // 获取账号信息
  getAccountInfo: (params) => Http.get(`${baseUrl}/info`, params),
  // 获取客户端认证验证码
  getClientCaptcha: (params, headers, successCb, errorCb) => Http.get(`${baseUrl}/cv/send-sms-v2`, params, headers, successCb, errorCb),
  // 提交客户端认证验证码
  submitClientCaptcha: (params) => Http.post(`${baseUrl}/cv/verify-sms-v2`, params,{ 'Content-Type': 'application/json' }),
  // refresh client auth info
  refreshClientAuth: () => Http.get(`${baseUrl}/cv/refresh`),
  // 帐号注销获取id
  getCancelId: () => Http.get(`${baseUrl}/cancel/getApplyEndDate`),
  // 账号注销撤回
  cancelGive: (params) => Http.get(`${baseUrl}/cancel/giveUp`, params),
  // 验证手机号码
  checkOperatorMobile: (params) => Http.post(`${baseUrl}/checkOperatorMobile`, params),
  // 滑块验证
  SliderCheck: (params) => Http.get(`${TARO_APP_BASE}/mpbp/bp/captcha/slide/check`, params),
  // 发送验证码
  SendCaptcha: (params) => Http.post(`${baseUrl}/captcha/send`, params),
  // 账号解冻
  UnFreezeAccount: (params) => Http.post(`${baseUrl}/unFreezeAccount`, params),
  // 账号信息获取
  getAccountStatus: (params) => Http.get(`${baseUrl}/pending/sdk`, params),
  // 账号信息修改
  editAccount: (params) => Http.post(`${baseUrl}/pending/sdk`, params,{ 'Content-Type': 'application/json' }),
  // 账号异常状态提示
  abnormalStatus: (params) => Http.get(`${baseUrl}/abnormal-status-info`, params),
  // 提交用户身份信息验证
  verifyUserInfo: (params) => Http.post(`${baseUrl}/secmobile/verify-user-info`, params,{ 'Content-Type': 'application/json' }),


  // 账号找回-新手机号绑定获取验证码
  recoverSmsCode: (params) => Http.get(`${baseUrl}/recover/sms-code`, params),
  // 账号找回-核验验证码
  recoverVerifyCode: (params) => Http.post(`${baseUrl}/recover/verify-code`, params,{ 'Content-Type': 'application/json' }),
  // 账号找回-实名验证
  recoverFaceCheck: (params) => Http.get(`${baseUrl}/recover/face-check`, params),
  // 账号找回-获取实名认证结果
  recoverFaceResult: (params) => Http.get(`${baseUrl}/recover/face-result`, params),
  // 账号找回-获取身份证关联账号信息
  recoverGetAccounts: (params) => Http.get(`${baseUrl}/recover/get-accounts`, params),
  // 账号找回-提交绑定
  recoverSubmitBinding: (params) => Http.post(`${baseUrl}/recover/submit-binding-info`, params,{ 'Content-Type': 'application/json' }),


  // 更换运营人-新手机号绑定获取验证码
  replaceSmsCode: (params) => Http.post(`${baseUrl}/captcha/send`, params),
  // 运营人更换手机号-获取cookie信息
  mobileGetCookie: (params) => Http.get(`${baseUrl}/operator/mobile/getCookie`, params),
  // 更换运营人-实名验证
  replaceFaceCheck: (params) => Http.get(`${baseUrl}/operator/mobile/face-check`, params),
  // 更换运营人-获取实名认证结果
  replaceFaceResult: (params) => Http.get(`${baseUrl}/operator/mobile/mini/face-result`, params),
  // 更换运营人-获取身份证关联账号信息
  replaceGetAccounts: (params) => Http.get(`${baseUrl}/operator/mobile/getAccounts`, params),
  // 更换运营人-提交绑定
  replaceSubmitBinding: (params) => Http.post(`${baseUrl}/operator/mobile/submit`, params,{ 'Content-Type': 'application/json' }),

}

export default apis
