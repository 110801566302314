//  小程序/h5 埋点统计
import { ref, onMounted } from 'vue';
import { Manager } from 'mini-program-tracker'
import baseConfig from '@/utils/trackUtils/spmConfig/config'
import store from '@/store'
import Taro from '@tarojs/taro'

export function mixinMethod() {


  /**
   * @description 埋点逻辑初始化，在app.js执行
   */
  const trackManagerInit = (options) => {
    Manager.setupConfigration({
      appId: baseConfig.appId,
      appName: baseConfig.appName,
      appVersion: baseConfig.appVersion,
      spmA: baseConfig.spmA,
      api: baseConfig.api,
      ext: {
        scene: options.scene,
      },
      requestOptions: baseConfig.requestOptions,
      isWeb: process.env.TARO_ENV === 'h5',
    });
  }

  /**
   * @description 页面初始化tracker
   */
  const initTracker = (spmB)=>{
    const pageTracker = Manager.createPageTracker (spmB, {"accountId": store.state.app.userInfo.id || ''});
    Manager.trackStart(pageTracker)
    Manager.trackPV(pageTracker) //PV上报
  }



  /**
   * @description 事件上报,上报前需创建页面的Tracker，执行createPageTracker方法
   */
  const sendSpm = (params) => {
    const { acode, clkParam } = params;
    if (!acode) return;
    if (!clkParam) {
      Manager.trackAction(acode);
    } else {
      // let reportParams = {};
      // clkParam.split(/[,;]/).map((itemStr) => {
      //   const [key, value] = itemStr.split(':');
      //   reportParams[key] = value;
      // });
      Manager.trackAction(acode, clkParam);
    }
  }

  return {
    trackManagerInit,
    initTracker,
    sendSpm
  }
}


