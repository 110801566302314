const content = {
  namespaced: true,
  state: {
    newsList: [],
    activeCate: 0,
    showDraft: false, // 发文是否提示编辑草稿
  },
  mutations: {
    UPDATE_NEWSLIST: (state, payload) => {
      state.newsList = payload;
    },
    UPDATE_ACTIVECATE: (state, payload) => {
      state.activeCate = payload;
    },
    UPDATE_SHOWDRAFT: (state, showDraft) => {
      state.showDraft = showDraft;
    },
  },
  actions: {
    UpdateNewsList: (ctx, columnList) => {
      ctx.commit('UPDATE_NEWSLIST', columnList)
    },
    UpdateActiveCate: (ctx, activeCate) => {
      ctx.commit('UPDATE_ACTIVECATE', activeCate)
    },
    UpdateShowDraft: (ctx, showDraft) => {
      ctx.commit('UPDATE_SHOWDRAFT', showDraft)
    },
  }
}

export default content
