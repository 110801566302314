import Taro from '@tarojs/taro'
import Cookies from 'js-cookie'

class Storage {
  set (key, value) {
    Taro.setStorageSync(key, value)
  }
  get (key) {
    return Taro.getStorageSync(key)
  }
  remove (key) {
    Taro.removeStorage({ key })
  }
  setCookie (key, value, time, domain) {
    Cookies.set(key, value, { expires: time, domain: domain })
  }
  getCookie (key) {
    return Cookies.get(key)
  }
  removeCookie (key) {
    Cookies.remove(key)
  }
}

export default new Storage()
