import Http from '../../utils/serviceUtils'
import Storage from '@/utils/storageUtils'
const { TARO_APP_BASE } = process.env
const baseUrl = TARO_APP_BASE

const apis = {
  // 验证手机号及邀请码
  checkInviteCode: (params) => Http.get(`${baseUrl}/mpbp/bp/account/vip/register/checkInviteCode`, params),
  // 获取验证码
  verificationCode: (params) => Http.post(`${baseUrl}/mpbp/bp/account/vip/register/send`, params),
  // 获取vip入驻信息
  getRegisterInfo: (params) => Http.get(`${baseUrl}/mpbp/bp/account/vip/register/getRegisterInfo`, params),
  // vip人脸识别接口获取
  getFaceUrl: (params) => Http.get(`${baseUrl}/mpbp/bp/account/vip/register/getFaceUrl`, params),
  // 获取城市信息
  getCityInfo: (params) => Http.get(`${baseUrl}/mpbp/bp/account/vip/register/getCityInfo`, params),
  // vip注册
  vipRegister: (params) => Http.post(`${baseUrl}/mpbp/bp/account/vip/register/regist`, params),
  // vip上传
  // registerUpload: (params) => Http.post(`${baseUrl}/mpbp/bp/account/vip/register/uploadFile?code=${Storage.get('registerCode')}`, params),
  // vip入驻人脸识别结果
  getVipFaceResult: (params) => Http.get(`${baseUrl}/mpbp/bp/account/vip/register/getFaceResult`, params),
  // 获取vip频道信息
  getChannel: (params) => Http.get(`${baseUrl}/mpbp/bp/account/common/channels`, params),

}

export default apis
