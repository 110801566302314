import Http from '../../utils/serviceUtils';
const { TARO_APP_BASE } = process.env;

const apis = {
  // 获取公告
  getNotice: (params) =>
    Http.post(`${TARO_APP_BASE}/mpbp/bp/notice/getNotices`, params, {
      'Content-Type': 'application/json',
    }),
  // 获取发布列表
  getNewsList: (params) =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/news/v4/users/news`, params),
  // 获取文章列表各类数
  getNewsCount: () =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/news/v4/users/newsInfo`),
  // 获取faq列表
  getFaqList: () => Http.get(`${TARO_APP_BASE}/mpbp/bp/fe/config/baikeMenu`),
  // 获取faq内容
  getFaqContent: (params) =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/news/v4/faq/${params.id}`),
  // 获取活动列表
  getActivityList: (params) =>
    Http.post(
      `${TARO_APP_BASE}/mpbp/bp/newActivity/listAllActivities`,
      params,
      { 'Content-Type': 'application/json' },
    ),
  // 获取素材库基本信息
  getMaterialInfo: () =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/user/resource/add/limit`),
  // 获取素材库列表
  getMaterialList: (params) =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/user/resource/page`, params),
  // 上传至素材库
  addResource: (params) =>
    Http.post(`${TARO_APP_BASE}/mpbp/bp/user/resource/add`, params),
  // 删除素材库图片
  deleteResource: (params) =>
    Http.post(`${TARO_APP_BASE}/mpbp/bp/user/resource/del`, params),
  // 获取文章内容
  getNewsItem: (params) =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/news/v4/article`, params),
  // 获取数据内容
  getDataList: (params) =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/news/v4/users/stat/overall`, params),
  // 查看运营人权限
  getOperateAuth: (params) =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/account/operator/authority`, params),
  // 获取收益各项数字
  getWithdrawNum: (params) =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/profit/user/getUserIncomeTotal`,
      params,
    ),
  // 获取平台收益明细
  getProfitDetail: (params) =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/profit/account/h5/getAccountIncomeDetail`,
      params,
    ),
  // 收益信息获取
  getProfitTotal: (params) =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/profit/account/getAccountIncomeTotal`,
      params,
    ),
  // 获取收益趋势
  getProfitTrend: (params) =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/profit/account/getAccountIncomeTrend`,
      params,
    ),
  // // 获取提现概览
  // getProfitOverview: () => Http.get(`${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/profit/user/getUserIncomeTotal`),
  // 是否可以提现
  checkCanWithdraw: () =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/profit/user/canWithdraw`,
    ),
  // 获取提现明细
  getWithdrawList: () =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/profit/user/getUserWithdrawDetail`,
    ),
  // 获取提现税费
  canWithdrawTax: () =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/profit/user/getWithdrawTax`,
    ),
  // 获取银行卡信息
  getCardInfo: () =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/bank/user/getBankCardInfo`,
    ),
  // 发起提现
  doWithdraw: (params) =>
    Http.post(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/profit/user/withdraw`,
      params,
      {
        'Content-Type': 'application/json',
      },
    ),
  // 绑定银行卡
  bindCard: (params) =>
    Http.post(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/bank/user/bindBankCard`,
      params,
      {
        'Content-Type': 'application/json',
      },
    ),
  // 更新银行卡
  updateCard: (params) =>
    Http.post(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/bank/user/reBindBankCard`,
      params,
      {
        'Content-Type': 'application/json',
      },
    ),
  // 发送验证码
  sendCaptcha: () =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/captcha/send`),
  // 校验验证码
  verifyCaptcha: (params) =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/captcha/verify`, params),
  // 删除文章
  deleteNews: (params, headers, successCb, errorCb) =>
    Http.post(
      params.type === 6
        ? `${TARO_APP_BASE}/v3/essay/${params.id}/delete`
        : `${TARO_APP_BASE}/mpbp/bp/news/v4/news/${params.id}/delete`,
      {},
      headers,
      successCb,
      errorCb,
    ),
  // 加精文章
  refineNews: (params) =>
    Http.post(
      params.priority === 70
        ? `${TARO_APP_BASE}/mpbp/bp/news/v4/users/identity/cancel/${params.id}/refine`
        : `${TARO_APP_BASE}/mpbp/bp/news/v4/users/identity/${params.id}/refine`,
    ),
  recallNews: (params, headers, successCb, errorCb) =>
    Http.post(
      `${TARO_APP_BASE}/mpbp/bp/news/v4/news/${params.id}/recall`,
      {},
      headers,
      successCb,
      errorCb,
    ),
  // 原创声明
  originalNews: (params) =>
    Http.post(`${TARO_APP_BASE}/mpbp/bp/news/v4/news/declare/original`, params),
  // 获取海报
  GetPoster: (params) =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/news/NewsPostCtrl/postCode/url`, params),
  getTopResult: () =>
    Http.get(`${TARO_APP_BASE}/mpbp/bp/news/v4/users/top/newsList`),
  getTopNews: (params) =>
    Http.post(
      `${TARO_APP_BASE}/mpbp/bp/news/v4/users/identity/topNews`,
      params,
    ),
  getFlows: (params) =>
    Http.post(`${TARO_APP_BASE}/mpbp/bp/cflow/getFlows`, params, {
      'Content-Type': 'application/json',
    }),
  getFlowNews: (params) =>
    Http.post(`${TARO_APP_BASE}/mpbp/bp/cflow/getNewsByFlowId`, params, {
      'Content-Type': 'application/json',
    }),
  renewalFlow: (params) =>
    Http.post(`${TARO_APP_BASE}/mpbp/bp/cflow/renewal`, params, {
      'Content-Type': 'application/json',
    }),

  // 平台收益-收益概览
  getProfitOverview: (params) =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/account/platform/profit/getProfitOverview`,
      params,
    ),

  // 平台收益-收益趋势-每日收益
  getDayProfitTrend: (params) =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/account/platform/profit/getDayProfitTrend`,
      params,
    ),

  // 平台收益-收益趋势-每月结算收益
  getMonthProfitTrend: (params) =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/account/platform/profit/getMonthProfitTrend`,
      params,
    ),

  // 平台收益-收益明细-每日收益
  getDayProfitDetail: (params) =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/account/platform/profit/getDayProfitDetail`,
      params,
    ),

  // 平台收益-收益明细-每月收益
  getMonthProfitDetail: (params) =>
    Http.get(
      `${TARO_APP_BASE}/mpbp/bp/mp-profit/inner/account/platform/profit/getMonthProfitDetail`,
      params,
    ),
  // 合作咨询
  ooperationSubmit: (params, headers, successCb, errorCb) =>
    Http.post(
      `${TARO_APP_BASE}/mpbp/bp/account/cooperation/submit`,
      params,
      headers,
      successCb,
      errorCb,
    ),
};

export default apis;
