const { NODE_ENV } = process.env;
const publicPath = NODE_ENV === 'development' ? '' : '/h5/v2';

const customRoutes = {
  // 可以通过数组为页面配置多个自定义路由
  '/pages/index/index': [publicPath + '/index'],
  '/pages/home/index': [publicPath + '/home'],
  '/pages/login/index': [publicPath + '/login'],
  '/pages/activity/index': [publicPath + '/activity'],
  '/pages/activity/detail': publicPath + '/act/:id',
  '/pages/announcement/index': [publicPath + '/announcement'],
  '/pages/message/index': [publicPath + '/message'],
  '/pages/contentManager/index': [publicPath + '/content-manager'],
  '/pages/data/index': [publicPath + '/data'],
  '/pages/earnings/index': [publicPath + '/earnings'],
  '/pages/faq/index': [publicPath + '/faq'],
  '/pages/faq/detail': [publicPath + '/faqDetail'],
  '/pages/feedback/index': [publicPath + '/feedback'],
  '/pages/feedback/detail': [publicPath + '/feedbackDetail'],
  '/pages/feedback/success': [publicPath + '/feedbackSuccess'],
  '/pages/faq/help': [publicPath + '/help_feedback'],
  '/pages/material/index': [publicPath + '/library'],
  '/pages/material/select': [publicPath + '/librarySelect'],
  '/pages/publishArticle/index': [publicPath + '/edit'],
  // '/pages/publishArticle/crop': [publicPath + '/crop'],
  // '/pages/publishArticle/text': [publicPath + '/text'],
  '/pages/register/index': [publicPath + '/personalEnter'],
  '/pages/register/registerAccountInfo': [publicPath + '/registerStep1'],
  '/pages/register/registerOperatorInfo': [publicPath + '/registerStep2'],
  '/pages/register/registerMaterialInfo': [publicPath + '/registerStep3'],
  '/pages/register/registerSuccess': [publicPath + '/registerSuccess'],
  '/pages/retrieveAccount/retrieveStatus': [publicPath + '/retrieveStatus'],
  '/pages/retrieveAccount/retrieveCheck': [publicPath + '/retrieveCheck'],
  '/pages/retrieveAccount/index': [publicPath + '/retrieveAccount'],
  '/pages/retrieveAccount/accountChecked': [publicPath + '/accountChecked'],
  '/pages/account/select': [publicPath + '/accounts/select'],
  '/pages/account/info': [publicPath + '/my'],
  '/pages/account/unlock': [publicPath + '/account/unlock'],
  '/pages/account/cancel': [publicPath + '/cancel'],
  '/pages/account/update': [publicPath + '/update'],
  '/pages/account/noAuth': [publicPath + '/noauth'],
  '/pages/contentTrace/index': [publicPath + '/contentTrace'],
  '/pages/preview/article': publicPath + '/preview/article',
  '/pages/preview/album': publicPath + '/preview/album/:id',
  '/pages/preview/video': publicPath + '/preview/video/:id',
  '/pages/clientAuth/index': [publicPath + '/clientAuth'],
  '/pages/withdraw/index': [publicPath + '/withdraw'],
  '/pages/withdraw/page': [publicPath + '/withdraw/page'],
  '/pages/withdraw/bindCard': [publicPath + '/withdraw/bindCard'],
  '/pages/vip/index': [publicPath + '/register'], // vip入驻入口
  '/pages/vip/accountInfo': [publicPath + '/accountInfo'], // vip入驻信息
  '/pages/vip/mssageLink': [publicPath + '/mssageLink'], // vip入驻状态
  '/pages/agent/index': [publicPath + '/agent'], // 智能助手
  '/pages/consult/index': [publicPath + '/consult'], // 合作咨询
  '/pages/authentication/index': [publicPath + '/authentication'], // 身份验证
  '/pages/retrievePhone/index': [publicPath + '/retrievePhone'], // 找回账号密码
  '/pages/retrievePhone/bindPhone': [publicPath + '/bindPhone'], // 手机号绑定
  '/pages/retrievePhone/realName': [publicPath + '/realName'], // 实名认证
  '/pages/retrievePhone/confirmAccount': [publicPath + '/confirmAccount'], // 账号信息确认
  '/pages/retrievePhone/bindResult': [publicPath + '/bindResult'], // 绑定结果
  '/pages/retrieveOperator/bindPhone': [publicPath + '/bindOperatorPhone'], // 更换运营人-手机号绑定
  '/pages/retrieveOperator/realName': [publicPath + '/realOperatorName'], // 更换运营人-实名认证
  '/pages/retrieveOperator/confirmAccount': [publicPath + '/confirmOperatorAccount'], // 更换运营人-账号信息确认
  '/pages/retrieveOperator/bindResult': [publicPath + '/bindOperatorResult'], // 更换运营人-账号找回结果
};

const dynamicRoutes = {
  [`${publicPath}/preview/article/:id`]: '/pages/preview/article',
};

module.exports = {
  customRoutes,
  dynamicRoutes,
};
