import Taro from '@tarojs/taro'
import axios from 'axios'
import qs from 'qs'
import store from '../store'
import { TaroAdapter } from "axios-taro-adapter";
import router from '@/router'
import Storage from '@/utils/storageUtils'
const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }

const instance = axios.create({
  timeout: 15000,
  baseURL: '',
  headers,
  adapter: TaroAdapter,
  withCredentials: true, // 跨域请求时发送 cookies
})



const getAccountId = () => {
  //从本地取账号ID，先取cookie，取不到取loacl
  let accountId = store.state.app.userInfo.id || Storage.getCookie('mpaccountId') || ''
  if(!accountId) {
    try {
      let account = JSON.parse(Storage.getCookie( 'currentAccount'));
      if(account) {
        accountId = account['id'];
      }
    } catch (err) {
      accountId = Storage.getCookie('mpaccountId')
    }

  }
  return accountId
}

instance.interceptors.request.use(
  config => {
    try {
      let headerConfig = {
        cookie: store.state.app.cookie || '',
        accountId: getAccountId(),
        'mp-cv': Storage.get('mp-cv')
      }
      if(Storage.getCookie('ssoToken')){
        headerConfig.ticket = Storage.getCookie('ssoToken')
      }
      for (const key in headerConfig) {
        if (headerConfig[key]) {
          config.headers[key] = headerConfig[key]
        }
      }
    } catch (err) {
      console.log(err)
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)


const handleErrorCode = (res) => {
  const { code, data, msg } = res.data
  const obj = {
    1211: () => {
      router.push('/pages/login/index')
    },
    1300: () => {
      if (data) {
        if (Array.isArray(data)) {
          const data1 = data.map(item => {
            const str = item.accountName ? item.accountName : '登录手机号'
            item.phoneAccount = str + '：' + item.mobile
            return item
          })
          store.dispatch('privacy/UpdateClientAuthInfo', { phone: data1 })
        }
        router.push('/pages/clientAuth/index')
      }
    },
    1307: () => { // 二次手机号身份验证
      // router.push('/pages/authentication/index', {phone: data})
      Taro.reLaunch({url: `/pages/authentication/index?phone=${data}`})
    },
    6000003: () => {
      Taro.showToast({ title: msg, icon: 'none' })
      router.push('/pages/account/select')
    },
    6000004: () => {
      Taro.showToast({ title: msg, icon: 'none' })
    },
    6000000: () => {
      Taro.showToast({ title: '您选择的账号状态发生了变化，请重新选择', icon: 'none' })
      router.push('/pages/account/select')
    },
    6000007: () => {
      Taro.showToast({ title: msg || '您选择的账号为禁言状态，被限制使用', icon: 'none' })
    },
    6000008: () => {
      Taro.showToast({ title: msg || '您选择的账号状态发生了变化，请重新选择', icon: 'none' })
      // Storage.set('currentAccountLock', store.state.account.currentAccount)
      // router.push('/pages/account/unlock')
    },
    6000009: () => {
      router.push('/pages/account/cancel')
    },
    // 安全分人脸核验达到上限
    8700000: () => {
      router.push('/pages/retrieveAccount/accountChecked', { checkedType: 3 })
    },
    // 安全分低 需要校验
    8800000: () => {
      Taro.showModal({
        title: '账号异常',
        content: '检测到您的账号近期行为异常，暂无法操作，请完成账号验证再试。',
        showCancel: false,
        success: (res) => {
          if (res.confirm) {
            router.push('/pages/retrieveAccount/accountChecked')
          }
        }
      })
    },
    9000000: () => {
      const { TARO_ENV } = process.env
      const { config } = res || {}
      const { url, params, method, errorCb, data,headers = {} } = config || {}
      console.log(config, 'config')
      if (TARO_ENV === 'weapp') {
        if (errorCb) {
          errorCb(res.data)
        }
      }
      if (TARO_ENV === 'h5') {
        return new Promise(async (resolve, reject) => {
          const captcha = new TencentCaptcha('194054508', async response => {
            const { ret, ticket, randstr } = response
            if (ret === 0) {
              const header = {
                check: 'true',
                type: 1,
                randonSrt: randstr,
                Tticket: ticket,
                key: encodeURIComponent(msg)
              }
              Object.assign(header, headers)
              // key: msg.replace('\n', '\\n')
              const fnRes = await Http[method](url, params || data, header)
              if (errorCb) {
                errorCb(fnRes)
              }
              resolve()
            }
          })
          captcha.show()
        })
      }
    },
    4000429: () => {
      return  Taro.showToast({ title: msg, icon: 'none' })
    },
    5000000: () => {
      if(msg){
        return  Taro.showToast({ title: msg, icon: 'none' })
      }
    }
  }
  if (obj[code]) {
    obj[code]()
  }
}

instance.interceptors.response.use(
  async (response) => {
    // console.log('请求地址：', response.config && response.config.url)
    // console.log('请求头：', response.config && response.config.headers)
    // console.log('请求参数：', response.config && response.config.data)
    // console.log('请求返回：', response.data)
    const code = response && response.data && response.data.code || null
    const rightCodes = ['200', 200, 2000, 2000000]
    const  successCb = response && response.config && response.config.successCb || null


    if (rightCodes.includes(code)) {
      if (successCb) {
        successCb(response.data)
      } else {
        return response.data
      }
    } else {
      await handleErrorCode(response)
      return response.data
    }
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
class Http {
  static get (url, params = {}, headers = {}, successCb, errorCb) {
    const accountId = getAccountId()
    const data = { ...params }
    if(!params.accountId){
      if (accountId && !url.includes(`accountId=${accountId}`)) {
        data.accountId = accountId
      }
    }
    const query = qs.stringify(data) ? '?' + qs.stringify(data) : ''
    return instance.get(url + query, { headers, successCb, errorCb })
  }

  /**
   *url 访问链接
   *params 请求参数
   *contentType 请求内容类型 urlencoded/json
  */
  static post (url, params, headers = { 'Content-Type': 'application/x-www-form-urlencoded' }, successCb, errorCb) {
    const accountId = getAccountId()
    let newUrl = url
    // 路径白名单
    const whitePathList = ['https://api.passport.sohu.com/sapi/g', 'https://api.passport.sohu.com/sapi/jf/code']
    if (accountId && !url.includes(`accountId=${accountId}`) && !whitePathList.includes(url)) {
      const sign =  url.includes('?code')?'&': '?'
      newUrl = url + sign + 'accountId=' + accountId
    }
    if (headers['Content-Type'] && headers['Content-Type'].includes('urlencoded')) {
      return instance.post(newUrl, qs.stringify(params), { headers, successCb, errorCb })
    } else if (headers['Content-Type'] && headers['Content-Type'].includes('form-data')) {
      if(Storage.getCookie('ssoToken')){
        headers.ticket = Storage.getCookie('ssoToken')
      }
      return axios({
        method: 'post',
        url: newUrl,
        data: params,
        headers,
        successCb,
        errorCb
      })
    } else {
      return instance.post(newUrl, params, {
        headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
        successCb,
        errorCb
      })
    }
  }
}

export default Http
