import { match } from 'path-to-regexp';
import utilMd5 from './md5.js';
import Storage from '@/utils/storageUtils.js';
import store from '@/store';
import router from '@/router';
import { dynamicRoutes } from '@/router/config.js';
import Exif from 'exif-js';
import apis from '@/api/api';
import Taro from '@tarojs/taro';
const { TARO_ENV } = process.env;
// 随机生成 uuid
const uuid = () => {
  var s = [];
  var hexDigits = '0123456789abcdef';
  for (var i = 0; i < 32; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  var uuid = s.join('');
  return uuid;
};
// 计算 sig
const getSigReqData = (data) => {
  var pArr = [];
  for (var x in data) {
    if (data[x] == undefined) {
      delete data[x];
      continue;
    }
    pArr.push(x + '=' + data[x]);
  }
  pArr.sort();
  return utilMd5.hex_md5(
    pArr.join('&') + 'py89E1jES1A0L3D49j3pu24KBJ9pUKdbe83H2Q435FVN8NP',
  ); // 全局变量存储 appKey,需要向 passport 服务端同事申请，这个只是测试 appKey
};

export function cutPicUrl(url, cutRule) {
  if (typeof url !== 'string') {
    return url;
  }
  // ['5b0988e595225.cdn.sohucs.com', '29e5534ea20a8.cdn.sohucs.com', 'img.mp.itc.cn', 'img.mp.sohu.com'] 后面两个先不管
  return url.replace(
    /(cdn\.sohucs\.com|img\.mp\.itc\.cn|img\.mp\.sohu\.com)\/(images|upload)/,
    `$1/${cutRule}/$2`,
  );
}

/**
 * 将ios旋转拍照还原
 * @param {el} img img
 * @param {Number} quality 转换后的图片质量，默认1
 * @param {Function} fn 完成回调函数，参数为转换完成的base64url
 */
export function restoreCameraImg(img, quality, fn) {
  // eslint-disable-next-line func-names
  Exif.getData(img, function () {
    const { width, height } = img;
    const Orientation = Exif.getTag(this, 'Orientation');
    fn(img.src);
    return;
    if (!Orientation || Orientation === 1) {
      fn(img.src);
    } else {
      // 解决ios拍照上传的旋转问题
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      switch (Orientation) {
        case 6: // 旋转90度
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(Math.PI / 2);
          ctx.drawImage(img, 0, -height, width, height);
          break;
        case 3: // 旋转180度
          ctx.rotate(Math.PI);
          ctx.drawImage(img, -width, -height, width, height);
          break;
        case 8: // 旋转-90度
          canvas.width = height;
          canvas.height = width;
          ctx.rotate((3 * Math.PI) / 2);
          ctx.drawImage(img, -width, 0, width, height);
          break;
        default:
          break;
      }
      const url = canvas.toDataURL('image/jpeg', quality || 1);
      fn(url);
    }
  });
}

// 统计字数
export const computeWordCount = (string, limit) => {
  let length = 0;
  let realLength = 0;
  let res = '';
  string.split('').forEach((value) => {
    value.match(/[^\x00-\xff]/gi) != null ? (length += 1) : (length += 0.5);
    if (limit !== undefined) {
      if (limit >= length) {
        res += value;
        realLength = length;
      }
    } else {
      realLength = length;
    }
  });
  return {
    string: limit === undefined ? string : res,
    length: Math.ceil(realLength),
  };
};

/*
 * 版本号比较方法
 * 传入两个字符串，当前版本号：curV；比较版本号：reqV
 * 调用方法举例：compare("1.1","1.2")，将返回false
 */
export function versionCompare(v1, v2) {
  const v1Attr = v1.split('.');
  const v2Attr = v2.split('.');
  let result;

  const minLen = Math.min(v1Attr.length, v2Attr.length);
  for (let i = 0; i < minLen; i++) {
    if (v1Attr[i] > v2Attr[i]) {
      result = true;
      break;
    } else if (v1Attr[i] < v2Attr[i]) {
      result = false;
      break;
    }
  }
  if (result == undefined) {
    if (v1Attr.length == v2Attr.length) {
      result = 'equal';
    } else if (v1Attr.length > v2Attr.length) {
      result = true;
    } else {
      result = false;
    }
  }
  return result;
}

/**
 * 图片裁剪 参数拼接
 * @param {String} url
 * @param {Array} params
 */
export const imgCompress = (url, params = []) => {
  try {
    let paramStr = params.join(',');
    if (/cdn\.sohucs\.com/g.test(url)) {
      // 兼容原有的压缩过图片链接，老链接不处理
      return url;
    }
    if (!paramStr || /^data:image\/(\w+);/g.test(url)) return url;
    const urlObject = new URL(url.startsWith('http') ? url : `https:${url}`);
    let { hostname, pathname } = urlObject;

    if (/^\/([A-Za-z]+_[A-Za-z0-9]+)/g.test(pathname)) {
      const pathArr = pathname.split('/');
      pathname = `/${paramStr}/${pathArr[2]}`;
    } else {
      pathname = `/${paramStr}${pathname}`;
    }
    return `//${hostname}${pathname}`;
  } catch (err) {
    return url;
  }
};

// 获取运行环境
export function getBrowser() {
  const { userAgent } = window.navigator;
  // 搜狐新闻客户端
  const SohuNewsApp =
    /SohuNews/i.test(userAgent) ||
    /com.sohu.sohu_mp_manager|com.sohu.newspaper|com.sohu.newspaper.inhouse|com.sohu.newsclient/i.test(
      userAgent,
    ) ||
    /SohuMpSdk/i.test(userAgent);
  // 手搜
  const app = /MSOHU/i.test(userAgent);
  // 高版本手搜
  const higherApp = Storage.getCookie('VGT1');
  // 狐小E
  const HXEApp = /SohuErpMobile/i.test(userAgent);
  // 安卓系统
  const android = /android/i.test(userAgent);
  // 微信
  const wechat = /micromessenger/i.test(userAgent);
  // 鸿蒙客户端SDK
  const HarmonyMpSdk = /HarmonyMpSdk/i.test(userAgent);

  return {
    SohuNewsApp,
    app,
    higherApp,
    HXEApp,
    android,
    wechat,
    HarmonyMpSdk,
  };
}

// 判断是否是IOS操作系统
export const isIos = () => {
  return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
};

export const logout = () => {
  //  主动退出登录，在不同情况下不同处理，正常情况下清除缓存和调用退出接口
  if (getBrowser.app) {
    // 在手搜app内，关闭h5
    if (
      window.Panther &&
      Panther.popBack &&
      typeof Panther.popBack === 'function'
    ) {
      Panther.popBack();
    }
    return;
  }
  const ppinf = Storage.getCookie('ppinf');
  Storage.removeCookie('ssoToken');
  Storage.removeCookie('mpToken');
  Storage.remove('currentAccountId');
  store.dispatch('app/UpdateUserInfo', {});
  store.dispatch('app/UpdateCookie', '');
  store.dispatch('app/UpdateCookieTime', '');
  if (ppinf) {
    if (TARO_ENV === 'h5') {
      const passport = new SohuPassport({
        appid: 113806,
        ptype: 1,
      });
      passport.logout({
        success: () => {
          Storage.removeCookie('ppinf');
          router.push('/pages/login/index');
        },
        error: () => {},
      });
    } else {
      apis.wxLogout();
      Taro.reLaunch({url: '/pages/index/index'});
    }
  } else {
    if (TARO_ENV === 'weapp') {
      apis.wxLogout();
    }
    Taro.reLaunch({url: '/pages/index/index'});
  }
};
function isObject(value) {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}
//  防抖和节流
export const debAndthrott = {
  debounce(func, wait, options) {
    let lastArgs;
    let lastThis;
    let maxWait;
    let result;
    let timerId;
    let lastCallTime;
    let lastInvokeTime = 0;
    let leading = false;
    let maxing = false;
    let trailing = true;
    if (typeof func !== 'function') {
      throw new TypeError('fun error');
    }
    wait = Number(wait) || 0;
    if (isObject(options)) {
      leading = !!options.leading;
      maxing = 'maxWait' in options;
      maxWait = maxing ? Math.max(Number(options.maxWait) || 0, wait) : maxWait;
      trailing = 'trailing' in options ? !!options.trailing : trailing;
    }

    function invokeFunc(time) {
      const args = lastArgs;
      const thisArg = lastThis;

      lastArgs = lastThis = undefined;
      lastInvokeTime = time;
      result = func.apply(thisArg, args);
      return result;
    }

    function leadingEdge(time) {
      // Reset any `maxWait` timer.
      lastInvokeTime = time;
      // Start the timer for the trailing edge.
      timerId = setTimeout(timerExpired, wait);
      // Invoke the leading edge.
      return leading ? invokeFunc(time) : result;
    }

    function remainingWait(time) {
      const timeSinceLastCall = time - lastCallTime;
      const timeSinceLastInvoke = time - lastInvokeTime;
      const result = wait - timeSinceLastCall;

      return maxing ? Math.min(result, maxWait - timeSinceLastInvoke) : result;
    }

    function shouldInvoke(time) {
      const timeSinceLastCall = time - lastCallTime;
      const timeSinceLastInvoke = time - lastInvokeTime;

      // Either this is the first call, activity has stopped and we're at the
      // trailing edge, the system time has gone backwards and we're treating
      // it as the trailing edge, or we've hit the `maxWait` limit.
      return (
        lastCallTime === undefined ||
        timeSinceLastCall >= wait ||
        timeSinceLastCall < 0 ||
        (maxing && timeSinceLastInvoke >= maxWait)
      );
    }

    function timerExpired() {
      const time = Date.now();
      if (shouldInvoke(time)) {
        return trailingEdge(time);
      }
      // Restart the timer.
      timerId = setTimeout(timerExpired, remainingWait(time));
    }

    function trailingEdge(time) {
      timerId = undefined;

      // Only invoke if we have `lastArgs` which means `func` has been
      // debounced at least once.
      if (trailing && lastArgs) {
        return invokeFunc(time);
      }
      lastArgs = lastThis = undefined;
      return result;
    }

    function cancel() {
      if (timerId !== undefined) {
        clearTimeout(timerId);
      }
      lastInvokeTime = 0;
      lastArgs = lastCallTime = lastThis = timerId = undefined;
    }

    function flush() {
      return timerId === undefined ? result : trailingEdge(Date.now());
    }

    function debounced() {
      const time = Date.now();
      const isInvoking = shouldInvoke(time);

      lastArgs = arguments;
      lastThis = this;
      lastCallTime = time;

      if (isInvoking) {
        if (timerId === undefined) {
          return leadingEdge(lastCallTime);
        }
        if (maxing) {
          // Handle invocations in a tight loop.
          timerId = setTimeout(timerExpired, wait);
          return invokeFunc(lastCallTime);
        }
      }
      if (timerId === undefined) {
        timerId = setTimeout(timerExpired, wait);
      }
      return result;
    }
    debounced.cancel = cancel;
    debounced.flush = flush;
    return debounced;
  },
  throttle(func, wait, options) {
    let leading = true;
    let trailing = true;

    if (typeof func !== 'function') {
      throw new TypeError('fun error');
    }
    if (isObject(options)) {
      leading = 'leading' in options ? !!options.leading : leading;
      trailing = 'trailing' in options ? !!options.trailing : trailing;
    }
    return debAndthrott.debounce(func, wait, {
      leading,
      maxWait: wait,
      trailing,
    });
  },
};
//  body滚动判断
export const bodyScrollJudge = {
  //  滚动条在Y轴上的滚动距离
  getScrollTop() {
    let scrollTop = 0;
    let bodyScrollTop = 0;
    let documentScrollTop = 0;
    if (document.body) {
      bodyScrollTop = document.body.scrollTop;
    }
    if (document.documentElement) {
      documentScrollTop = document.documentElement.scrollTop;
    }
    scrollTop =
      bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
    return scrollTop;
  },
  setScrollTop(num) {
    if (document.body) {
      document.body.scrollTop = num;
    }
    if (document.documentElement) {
      document.documentElement.scrollTop = num;
    }
  },
  //  文档的总高度
  getScrollHeight() {
    let scrollHeight = 0;
    let bodyScrollHeight = 0;
    let documentScrollHeight = 0;
    if (document.body) {
      bodyScrollHeight = document.body.scrollHeight;
    }
    if (document.documentElement) {
      documentScrollHeight = document.documentElement.scrollHeight;
    }
    scrollHeight =
      bodyScrollHeight - documentScrollHeight > 0
        ? bodyScrollHeight
        : documentScrollHeight;
    return scrollHeight;
  },

  //  浏览器视口的高度
  getWindowHeight() {
    let windowHeight = 0;
    if (document.compatMode === 'CSS1Compat') {
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowHeight = document.body.clientHeight;
    }
    return windowHeight;
  },
  touchBottom(num) {
    return (
      this.getScrollTop() + this.getWindowHeight() >
      this.getScrollHeight() - num
    );
  },
};

export function UrlParamHash(url, key) {
  url = decodeURIComponent(url);
  const params = [];
  const hash = url.slice(url.indexOf('?') + 1).split('&');
  for (let i = 0; i < hash.length; i++) {
    const equal = hash[i].indexOf('=');
    const itemk = hash[i].slice(0, equal);
    const itemv = hash[i].slice(equal + 1);
    params[itemk] = itemv;
  }
  return params[key] || '';
}

export function getMpToken() {
  if (process.env.TARO_ENV === 'h5') {
    const urlParams = new URLSearchParams(window.location.search);
    const mpToken = urlParams.get('mpToken');
    if (mpToken) {
      Storage.set('mpToken', mpToken);
      Storage.setCookie('mpToken', mpToken);
      urlParams.delete('mpToken');
      window.location.href = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
    }
  }
}

export function getDynamicPath(actualPath, params) {
  let toConvertRoute = null;
  let matchedRoute = null;
  let matcher = null;
  Object.keys(dynamicRoutes).some((route) => {
    matcher = match(route)(actualPath);
    if (matcher) {
      matchedRoute = route;
      toConvertRoute = dynamicRoutes[route];
    }
    return matcher;
  });

  if (matcher) {
    return `${toConvertRoute}${router.handleParams({ ...params, ...matcher.params })}`;
  }

  return null;
}

export function getUrlParams(name, url) {
  const urlReg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i'); // 定义正则表达式
  let r;
  if (url) {
    r = url.split('?') && url.split('?')[1].match(urlReg);
  } else {
    r = window.location.search.substr(1).match(urlReg);
  }
  if (r != null) return unescape(r[2]);
  return null;
}

export function getAccountId () {
  //从本地取账号ID，先取cookie，取不到取loacl
  let accountId = store.state.app.userInfo.id || Storage.getCookie('mpaccountId') || ''
  if(!accountId) {
    try {
      let account = JSON.parse(Storage.getCookie( 'currentAccount'));
      if(account) {
        accountId = account['id'];
      }
    } catch (err) {
      accountId = Storage.getCookie('mpaccountId')
    }

  }
  return accountId
}

export default {
  getSigReqData,
  uuid,
  getBrowser,
  logout,
  UrlParamHash,
  getMpToken,
  getDynamicPath,
  getUrlParams,
  getAccountId
};
