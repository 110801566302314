import Http from '../../utils/serviceUtils'
const { TARO_APP_BASE } = process.env
const baseUrl = TARO_APP_BASE + '/mpbp/bp/account'

const apis = {
  // 获取未读站内信总数
  getUnreadNum: (params) => Http.get(`${baseUrl}/message/unread-num`, params),

  // 获取站内信列表
  getMessages: (params) => Http.post(`${baseUrl}/message/get-messages`, params, { 'Content-Type': 'application/json' }),

  // 单条站内信已读
  readMessage: (params) => Http.post(`${baseUrl}/message/read-message`, params, { 'Content-Type': 'application/json' }),

  // 阅读所有站内信
  readAllMessage: (params) => Http.post(`${baseUrl}/message/read-all-message`, params, { 'Content-Type': 'application/json' }),

  // 清除未读数字角标
  clearUnreadNum: (params) => Http.post(`${baseUrl}/message/clear-unread-num`, params, { 'Content-Type': 'application/json' }),

}

export default apis
