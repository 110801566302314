import Http from '@/utils/serviceUtils';
const { TARO_APP_BASE } = process.env;
import Taro from '@tarojs/taro';
import Storage from '@/utils/storageUtils';
import store from '../../store';

const BASE_URL = `${TARO_APP_BASE}/mpbp/bp/mpai/dify`;

/**
 * 提供反馈的函数
 * @param {string} messageId - 消息的ID
 * @param {boolean} isLike - 是否喜欢该消息
 * @returns {Promise} - 包含请求结果的Promise对象
 */
export function feedback(messageId, rating) {
  const params = { messageId, rating };
  return Http.get(`${BASE_URL}/feedbacks`, params);
}

/**
 * 获取建议的函数
 * @param {string} messageId - 消息的ID
 * @returns {Promise} - 包含请求结果的Promise对象
 */
export function getSuggested(messageId) {
  const params = { messageId };
  return Http.get(`${BASE_URL}/suggested`, params);
}
/**
 * 获取历史消息
 * @param {string} conversationId - 会话ID
 * @param {string} firstId - 第一条消息ID
 * @param {number} limit - 消息数量限制，默认为20
 * @returns {Promise} - 返回一个Promise对象，包含历史消息的结果
 */
export function fetchHistoryMessage(conversationId, firstId, limit = 20) {
  const params = {
    conversationId,
    limit,
  };

  if (firstId) {
    params.lastId = firstId;
  }
  return Http.get(`${BASE_URL}/messages`, params);
}

/**
 * 获取会话列表
 * @param {string} lastId - 最后一条会话的ID
 * @param {number} limit - 会话数量限制，默认为20
 * @returns {Promise} - 返回一个Promise对象，包含会话列表的结果
 */
export function fetchConversations(lastId, limit = 20) {
  const params = {
    limit,
  };

  if (lastId) {
    params.lastId = lastId;
  }

  return Http.get(`${BASE_URL}/conversations`, params);
}

/**
 * 停止聊天任务
 * @param {string} taskId - 任务ID
 * @returns {Promise} - 返回一个Promise对象，包含停止聊天任务的结果
 */
export function stopChat(taskId) {
  const params = {
    taskId,
  };
  return Http.get(`${BASE_URL}/stop`, params);
}

/**
 * 获取指南的函数
 * @returns {Promise} - 包含请求结果的Promise对象
 */
export function getGuide() {
  return Http.get(`${BASE_URL}/parameters`);
}

function parseMessage(message) {
  return message
    .trim()
    .split('\n\n')
    .map((line) => {
      const jsonStr = line.replace(/^data:/, '');
      try {
        return JSON.parse(jsonStr);
      } catch (error) {
        return {};
      }
    });
}

async function messageHandler(stream, onMessage, onDone) {
  const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { value, done } = await reader.read();
    if (done) {
      onDone();
      break;
    }
    parseMessage(value).forEach((item) => {
      const {
        answer,
        event,
        message_id: messageId,
        task_id: taskId,
        conversation_id: conversationId,
      } = item;
      if (event === 'message')
        onMessage({ answer, messageId, taskId, conversationId });
    });
  }
}

/**
 * 获取消息的函数
 * @param {string} question - 用户提出的问题
 * @param {function} onMessage - 消息处理函数
 * @param {object} options - 请求选项
 * @returns {Promise} - 包含请求结果的Promise对象
 */
export function fetchMessage(question, conversationId, onMessage, options) {
  const accountId = store.state.app.userInfo.id;
  const url = `${BASE_URL}/chat?query=${question}&accountId=${accountId}${conversationId ? '&conversationId=' + conversationId : ''}`;
  return new Promise((resolve) => {
    if (process.env.TARO_ENV === 'h5') {
      fetch(url, {
        headers: {
          accept: '*',
          'content-type': 'application/json',
        },
        method: 'GET',
        ...options,
      }).then(async (response) => {
        await messageHandler(response.body, onMessage, resolve);
      });
    } else {
      const requestTask = Taro.request({
        url,
        enableChunked: true,
        responseType: 'arraybuffer',
        method: 'GET',
        header: {
          cookie: store.state.app.cookie || '',
          accountId: store.state.app.userInfo.id,
          'mp-cv': Storage.get('mp-cv'),
        },
        timeout: 300e3,
      });

      requestTask.onChunkReceived(async function chunkReceivedHandler(res) {
        parseMessage(new TextDecoder().decode(res.data)).forEach((item) => {
          const {
            answer,
            event,
            message_id: messageId,
            task_id: taskId,
            conversation_id: conversationId,
          } = item;
          if (event === 'message')
            onMessage({ answer, messageId, taskId, conversationId });
          if (event === 'message_end') {
            resolve();
            requestTask.offChunkReceived(chunkReceivedHandler);
          }
        });
      });
    }
  });
}
