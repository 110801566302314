const privacy = {
  namespaced: true,
  state: {
    clientAuthInfo: { // 客户端认证相关信息
      phone: '' // 手机号
    }
  },

  mutations: {
    SET_CLIENTAUTHINFO: (state, info) => {
      state.clientAuthInfo = info
    }
  },

  actions: {
    UpdateClientAuthInfo(ctx, info) {
      ctx.commit('SET_CLIENTAUTHINFO', info)
    }
  }
}

export default privacy
