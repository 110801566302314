import Taro from '@tarojs/taro'
import apis from '@/api/api'
import Storage from '@/utils/storageUtils'
import store from '@/store'
import CommonUtils from '@/utils/commonUtils'
import router from '@/router'

class initBaseInfo {
  constructor () {
    this.total = 0
    this.accountList = []
  }
  // 微信初始化获取必要参数gid/jv/openId
  async getWxInfo () {
    try {
      // 获取设备信息
      const sysInfo = Taro.getSystemInfoSync()
      store.dispatch('app/UpdateSysInfo', sysInfo)
      // 整合公共参数
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        'PP-OS': sysInfo.system,
        'PP-DV': sysInfo.model,
        'PP-VS': '1.0.0',
        'PP-UA': 'chrome',
        'PP-HW': sysInfo.screenHeight + ',' + sysInfo.screenWidth,
        'PP-APPID': '113807'
      }
      let params = {
        appid: '113807',
        vs: '1.0.0',
        timestamp: Date.parse(new Date()),
        nonce: CommonUtils.uuid(),
      }
      params.sig = CommonUtils.getSigReqData(params)
      // 获取gid
      const gidInfo = await apis.GetGid(params, headers)
      const gid = gidInfo?.data?.gid || ''
      store.dispatch('app/UpdateGid', gid)
      // 获取jv
      headers['PP-GID'] = gid
      const jvInfo = await apis.JvCode(params, headers)
      const jv = jvInfo.data
      store.dispatch('app/UpdateJv', jv)
      // 获取openId
      Taro.login({
        success: async (res) => {
          const { code, errMsg } = res
          if (code) {
            const params = {
              jv: jv,
              gid: gid,
              openId: '',
              system: sysInfo.system,
              model: sysInfo.model,
              screenHeight: sysInfo.screenHeight,
              screenWidth: sysInfo.screenWidth,
              code: code
            }
            // console.log(code, 'code')
            const result = await apis.checkLogin(params)
            // code为200时返回的是ppinf等登录态cookie，可直接登录
            if (result.code === 200) {
              store.dispatch('app/UpdateCookie', result.data)
              store.dispatch('app/UpdateCookieTime', new Date().getTime())
              Storage.set('openId', result.openId || '')
              this.initProcess()
            } else {
              if (result.data) {
                // 非200状态码返回openid
                store.dispatch('app/UpdateOpenId', result.data)
                Storage.set('openId', result.data)
              }
              Taro.reLaunch({url: '/pages/index/index'})
            }
          } else {
            Taro.reLaunch({url: '/pages/index/index'})
            console.log('Login failed' + errMsg)
          }
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
  async initProcess () {
    // 获取账号信息
    const accountList = await this.getAccountInfo()
    // 成功获取到账号信息后，确定即将前往的页面
    accountList && await this.initGoPath()
  }
  // 获取账号列表
  async getAccountInfo () {
    // 不需要登录的路由直接跳过
    const noLoginPath = ['/pages/announcement/index']
    // 合作咨询无需登录
    const fromPath = Taro.getCurrentInstance().router?.path
    const whiteList = new RegExp(noLoginPath.join('|'))
    const path = Storage.get('toPath')
    const checkLogin = !whiteList.test(path)
    if (!checkLogin || fromPath.includes('/consult')) {
      return
    }
    try {
      // 校验passport信息
      const passport = await apis.accountAndUserCheck()
      const { data } = passport || {}
      switch (data.code) {
        case 2: // 审核驳回，前往入驻页
          Taro.redirectTo({url: '/pages/register/registerAccountInfo?auditType=1'})
          return
        case 0: // 审核中，前往结果页
          Taro.redirectTo({url: '/pages/register/registerSuccess'})
          return
        case -1: // 用户不存在，调接口转为游客 调用预入驻
          Taro.redirectTo({url: '/pages/register/index'})
          return
      }

      // 获取账号列表
      const accounts = await apis.getAccounts()
      const result = accounts?.data || {}
      const accountList = result.data || []
      const { total = 0, innerUser = false } = result
      this.total = total
      this.accountList = accountList
      store.dispatch('app/UpdateInnerAcc', innerUser)
      store.dispatch('app/UpdateTotalAccount', total)
      return accountList
    } catch (err) {
      console.log(err)
    }
  }
  // 初始化默认态应前往哪页
  async initGoPath () {
    const total = this.total
    const accountList = this.accountList
    // 校验缓存，用户是否已选择过账号
    const currentAccount = store.state.app.userInfo
    if (currentAccount && currentAccount.id) {
      let infos = []
      // 获取所有账号
      accountList.forEach(item => { infos = infos.concat(item.accountInfos) })
      // 已选择过的账号(缓存)是否能在账号列表中找到,且状态是否正确
      const account = infos.find(item => item.id === currentAccount.id && item.status === currentAccount.status)
      // 缓存合法直接进入，不合法则清空
      if (account) {
        this.gotoPage(currentAccount)
      } else {
        store.dispatch('app/UpdateUserInfo', {})
      }
    }
    // 没有缓存，则按账号列表数量判断进入哪个页面
    // 单账号直接进，多账号去选号
    if (total === 1) {
      const item = accountList[0] || {}
      const { accountInfos } = item || []
      const account = accountInfos[0] || {}
      this.gotoPage(account)
    } else {
      // 多账号
      // 1. 检查是否选择过
      // 1.1 有，无异常（有号且号状态正常）进入选择的号
      // 1.2 没有，则进选择页
      if (currentAccount && currentAccount.id) {
        this.gotoPage(currentAccount)
      } else {
        Taro.reLaunch({url: '/pages/account/select'})
        return
      }
    }
  }
  // 按账号状态前往页面
  gotoPage (account) {
    let goPath = ''
    let query = {}
    const { status } = account
    const currentAccount = store.state.app.userInfo
    const eventInfo = store.state.app.eventInfo
    // console.log('gotoPage', currentAccount);

    switch (status) {
      case 0: // 待审核
        goPath = '/pages/register/index?auditType=2'
        query = { auditType: 2 }
        break;
      case 1: // 通过
        // 用户登录过，无需前往至默认页面，直接return
        const fromPath = Taro.getCurrentInstance().router?.path
        if (currentAccount.id && !fromPath.includes('/login') && fromPath !== '/h5/v2/') {
          // 刷新保留当前页面
          return
        } else {
          goPath = '/pages/home/index'
        }
        // 用户首次进，前往默认页面
        store.dispatch('app/UpdateUserInfo', account)
        if (eventInfo.activityType) {
          goPath = '/pages/event/index'
        } else {
          goPath = '/pages/home/index'
        }
        break;
      case 2: // 驳回
        // goPath = '/pages/account/select'
        store.dispatch('app/UpdateUserInfo', account)
        goPath = '/pages/home/index'
        break;
      case 3: // 查封
        // goPath = '/pages/account/select'
        store.dispatch('app/UpdateUserInfo', account)
        goPath = '/pages/home/index'
        break;
      case 5: // 新手期
        // // 用户登录过，无需前往至默认页面，直接return
        // if (currentAccount.id) {
        //   return
        // }
        // // 用户首次进，前往默认页面
        // store.dispatch('app/UpdateUserInfo', account)
        // if (eventInfo.activityType) {
        //   goPath = '/pages/event/index'
        // } else {
        //   goPath = '/pages/home/index'
        // }
        goPath = '/pages/register/index'
        break;
      default: // 其他异常情况去登录页
        goPath = '/pages/login/index'
    }
    console.log('即将前往的路由信息:', goPath, query)
    // router.push(goPath, query)
    // Taro.navigateTo({url: goPath})
    Taro.reLaunch({url: goPath})
  }
}

export default new initBaseInfo()
