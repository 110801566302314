// import { spmb, acode } from './spmCode';
import * as Taro from '@tarojs/taro-h5';

const baseConfig = {
  appId: '',
  appName: 'h5-搜狐号',
  spmA: 'smwmp',
  appVersion: '1.0.1',
  api: Taro,
  // spmb,
  // acode,
  requestOptions: {
    keepalive: true,
  },
};
export default baseConfig;
