import Storage from '@/utils/storageUtils'
import CommonUtils from '@/utils/commonUtils'
const { TARO_ENV } = process.env

class uuid {
  // 如果没有mp-cv则写入
  set () {
    const storage = Storage.get('mp-cv')
    if (!storage) {
      // 客户端
      let os = ''
      if (TARO_ENV === 'h5') {
        os = '101'
      }
      if (TARO_ENV === 'weapp') {
        os = '102'
      }
      // 时间戳
      const timestamp = new Date().getTime()
      // 唯一标识
      const reg = new RegExp(/-/g)
      const uuid = CommonUtils.uuid()
      // 整合参数唯一key
      const key = `${os}-${timestamp}-${uuid}`
      Storage.set('mp-cv', key)
      Storage.setCookie('mp-cv', key, 400 * 24 * 60 * 60 * 1000)
    } else {
      Storage.setCookie('mp-cv', storage, 400 * 24 * 60 * 60 * 1000)
    }
  }
  remove () {
    Storage.remove('mp-cv')
    Storage.removeCookie('mp-cv')
  }
}

export default new uuid()
