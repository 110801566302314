import Taro from '@tarojs/taro';
import { createApp } from 'vue';
import { IconFont } from '@nutui/icons-vue-taro';
import store from './store';
import '@/style/index.less';
import { getBrowser, getUrlParams, getDynamicPath } from '@/utils/commonUtils';
import uuidIns from '@/utils/uuidUtils';
import { dsn } from '@/utils/sentry';
import monitor from '@crossbow/monitor';
import echarts from '@/utils/echarts.min.js';
import { loadEcharts } from 'echarts4taro3';
loadEcharts(echarts);
const { TARO_ENV } = process.env;
import eCardSDK from './mp_ecard_sdk/main.js';
import { mixinMethod } from '@/utils/trackUtils';
import Storage from '@/utils/storageUtils';
import initBaseInfoIns from '@/utils/initBaseInfoUtils'
import lcpSdk from 'lcp-sdk'
import apis from '@/api/api';
const { trackManagerInit } = mixinMethod();

const app = createApp({
  // 对应 onLaunch
  onLaunch(options) {
    const { scene='' } = options.query
    // 通过扫码更换运营人获取登录信息
    if (scene && options.path === 'pages/retrieveOperator/realName'){
      apis.mobileGetCookie({scene}).then((res)=>{
        const {data, code} = res
        if (code == 2000000) {
          store.dispatch('app/UpdateCookie', data.cookie)
          store.dispatch('app/UpdateCookieTime', new Date().getTime())
          apis.getAccountInfo({accountId: data.accountId}).then(res => {
            const { data = {}, code } = res || {};
            if (code === 2000000) {
              store.dispatch('app/UpdateUserInfo', data);
            }
          })
        }
      })
    }
    trackManagerInit(options);
    const path = Taro.getCurrentInstance().router?.path
    if (TARO_ENV === 'weapp') {
      // 小程序接入e证通初始化
      eCardSDK.initEid();
    } else {
      lcpSdk.init({
        acode: 10914,
        env: 'h5',
        clkParam: {
          appcode: 'mpwap',
          path,
          terminal: TARO_ENV
        }
      })
    }
  },
  onError(error) {
    monitor.captureException(error);
  },

  onPageNotFound({ path, query }) {
    console.warn('Page Not Found:', path);
    const dynamicPath = getDynamicPath(path, query);
    if (dynamicPath) {
      return Taro.redirectTo({ url: dynamicPath });
    }
    if (path === '/h5/v2/') {
      return initBaseInfoIns.initProcess()
    }
    Taro.redirectTo({ url: '/pages/index/index' });
  },
});

monitor.init({
  app,
  dsn,
  beforeSend(event) {
      if (event.level === 'info') return null;
      if (event.exception
          && Array.isArray(event.exception.values)
          && /^Non-Error exception captured/ig.test(event.exception.values[0].value)
      ) return null;
      return event;
  }
});

// 非小程序加载第三方js
if (window.__wxjs_environment !== 'miniprogram') {
  const list = [
    'https://turing.captcha.qcloud.com/TCaptcha.js',
    // 'https://statics.itc.cn/spm/prod/js/1.0.2/index.js',
  ];
  for (const item of list) {
    const script = document.createElement('script');
    script.src = item;
    document.body.appendChild(script);
  }
}

//  狐小e内部号登录
const hxeToken = getUrlParams('ticket');
// console.log('狐小e', hxeToken);
if (hxeToken) Storage.setCookie('ssoToken', hxeToken);

app.config.globalProperties.TARO_ENV = TARO_ENV;
const browser = getBrowser();
if (!browser.SohuNewsApp) {
  uuidIns.set();
} else if(!browser.HarmonyMpSdk){
  uuidIns.remove();
  // const mpCv = Storage.getCookie('mp-cv');
  // console.log('客户端mpCv', mpCv);
  // alert('客户端mpCv');
  // alert(mpCv);
  // Storage.set('mp-cv', mpCv);
}

app.use(store);
app.use(IconFont);

export default app;
