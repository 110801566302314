import Http from '../../utils/serviceUtils'
import Storage from '@/utils/storageUtils'

const { TARO_APP_BASE } = process.env
const baseUrl = `${TARO_APP_BASE}/mpbp/bp/account/weixin-mp`

const apis = {
  // 查询登录状态
  checkLogin: (params) => Http.post(`${baseUrl}/session`, params),
  // 账号密码登录
  pwdLogin: (params) => Http.post(`${baseUrl}/login/pwd`, params),
  // 微信授权登录（passport手机号解密方式）
  wxappletLogin: (params) => Http.post(`${baseUrl}/login/wxapplet-mlogin`, params),
  // 手机号验证码登录
  phoneLogin: (params) => Http.post(`${baseUrl}/login/mcode`, params),
  // 微信第三方登录
  wxLogin: (params) => Http.post(`${baseUrl}/login/wxapplet`, params),
  // 获取openid
  getOpenId: (params) => Http.post(`${baseUrl}/mini/openid`, params),
  // 微信登出
  wxLogout: () => Http.post(`${baseUrl}/logout?openId=${Storage.get('openId')}`)
}

export default apis
